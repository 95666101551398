@import "mixins";
@import "bourbon";

@font-face {
  font-family: Futura;
  src: url("../fonts/Futura-Bold-03.ttf");
  font-style: normal;
}


@font-face {
  font-family: "Futura Light";
  src: url("../fonts/Futura-Medium-01.ttf");
  font-style: normal;
}


// @font-face {
//   font-family: Montserrat;
//   src: url("../fonts/Montserrat-SemiBold.ttf");
//   font-style: normal;
// }


// @font-face {
//   font-family: "Montserrat Light";
//   src: url("../fonts/Montserrat-Light.ttf");
//   font-style: normal;
// }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  font-family: Futura, Arial, sans-serif;
  background-color: #000000;
  -ms-word-break: break-word;
  word-break: break-word;
  font-size: 14px;
  line-height: 150%;
  position: relative;
}

a {
  font-family: "Futura Light", Arial, sans-serif;
  text-transform: uppercase;
  color: #FFFFFF;
  text-decoration: none;
  &:hover {
    color: #BAA965;
    text-decoration: none;
  }
}

.text-warning {
  color: #BAA965 !important;
}

.btn-warning {
  color: #ffffff !important;
  background-color: #BAA965 !important;
  border-color: #BAA965 !important;
  &:hover {
    color: #050505 !important;
    background-color: #ffffff !important;
  }
}

.btn-outline-warning {
  color: #BAA965 !important;
  border-color: #BAA965 !important;
  &:hover {
    color: #050505 !important;
    background-color: #BAA965 !important;
  }
}

.img-responsive {
  width: 100% !important;
  height: auto;
}

#header {
  background-color: #050505;
  width: 100%;
  height: 900px;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.cover-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  @media (max-width: 992px) {
    background-size: cover;
    background: url("../img/video-bg-mobile.jpg") no-repeat center center;    
  }

  &:after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // background: url("../img/shadow-video.png") repeat-x bottom center;
    /* Rectangle 10 */
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 40%, #000000 100%);
    // background-size: cover;
    @media (max-width: 992px) {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.15) 40%, #000000 100%);
      // display: none;
    }

  }
  iframe, video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 500%;
    height: 150%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    @media (max-width: 992px) {
      display: none;
    }

  }
}

.video-box {
  border: 1px solid #4d4c4c;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  .play {
    background: url("../img/button_play.svg") no-repeat center center;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    width: 64px;
    height: 64px;
    display: block;
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50% - 35px);
    opacity: 0.5;
  }
}

.new-product {
  background: url("../img/new.png") no-repeat left center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  width: 100px;
  height: 50px;
  display: block;
}

.title-tasty {
  padding-top: 55px;
  font-size: 40px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #BAA965;
  text-shadow: 0px 4px 7px rgba(0, 0, 0, 0.5);
}

.subtitle {
  /* Aunthenticity in every bite, Extravagance in every meal, Indulgence in every moment. */
  font-family: "Montserrat", Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #BAA965;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.title-hastag {
  /* #EnaknyaBikinNagih */
  font-family: "Montserrat", Arial, sans-serif;
  font-style: italic;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #BAA965;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  margin-bottom: 50px;
}

.tasty-delicacy {
  /* A TASTY DELICACY */
  text-transform: uppercase;
  font-size: 33px;
  letter-spacing: 0.05em;
  color: #BAA965;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

#overlay-delicacy1 {
  background: url("../img/overlay-delicacy1.png") no-repeat top center;
  width: 152px;
  height: 180px;
  position: absolute;
  left: 0%;
  top: 0%;
  z-index: 9;
}

#overlay-delicacy2 {
  background: url("../img/overlay-delicacy2.png") no-repeat top center;
  width: 152px;
  height: 180px;
  position: absolute;
  top: -2%;
  z-index: 9;
}

#overlay-delicacy3 {
  background: url("../img/overlay-delicacy3.png") no-repeat top center;
  width: 152px;
  height: 180px;
  position: absolute;
  right: -10%;
  top: 1%;
  z-index: 9;
}

.subtasty-delicacy {
  /* When you're craving for something tasty, get yours with our selected ingredients that brings together all the savoury flavours in one Mie Sedaap Tasty servings. */
  font-family: "Montserrat", Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
}

.margin-tasty-1 {
  margin-top: 70px;
}

.delicacy-title {
  /* CHEWY NOODLES */
  /* CRUNCHY CHICKEN BITS */
  /* AUTHENTIC CHICKEN MEAT */
  text-transform: uppercase;
  font-size: 20px;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  span {
    color: #BAA965;
    clear: both;
    width: 100%;
    display: inline-block;
  }
}

.delicacy-info {
  /* As chewy as it can get, experience noodles like you've never been before with our chewy and elastic noodles that will surely the bring the best out of our selected ingredients. */
  /* We all crave crunch. Get that crunchy sensation with our crunchy chicken bits that will surely complement your experience. */
  /* What you see is what you get with Mie Sedaap Tasty, our juicy authentic chicken meat will surely bring delight in every bite. */
  font-family: "Montserrat", Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.margin-tasty-1 .delicacy-title:before {
  content: "";
  background: url("../img/mie.png") no-repeat top center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  width: 233px;
  height: 120%;
  position: absolute;
  left: -165px;
  top: -20px;
  z-index: 9;
}

.margin-tasty-2 {
  margin-top: 100px;
  .delicacy-title:before {
    content: "";
    background: url("../img/crunchy.png") no-repeat top center;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    width: 320px;
    height: 300px;
    position: absolute;
    right: -165px;
    top: -20px;
    z-index: 9;
  }
}

.margin-tasty-3 {
  .delicacy-title:before {
    content: "";
    background: url("../img/meat.png") no-repeat top center;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    width: 200px;
    height: 100%;
    position: absolute;
    left: -135px;
    top: -20px;
    z-index: 9;
  }
  margin-top: 100px;
}

.margin-tasty-4 {
  margin-top: 520px;
}

#content-tasty {
  position: relative;
}

.parallax-top {
  background-size: contain;
  position: absolute;
  z-index: 9;
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  pointer-events: none;
  img {
    display: inline-block;
    width: 100%;
    max-width: 100%;
  }
  &.kriuk {
    left: -140px;
    top: -260px;
    // background-color: yellow;
    // background-image: url("../img/top/kriuk-2.png");
    width: 400px;
    // height: 600px;
    @media (max-width: 992px) {
      left: -100px;
      width: 200px;
    }

  }
  &.daun {
    // background-color: purple;
    right: -320px;
    top: 50px;
    // background-image: url("../img/middle/daun.png");
    width: 920px;
    // height: 600px;;
  }  
  &.daun-blur {
    left: 50%;
    margin-left: -200px;
    top: -50px;
    width: 920px;
    @media (max-width: 992px) {
      width: 400px;
    }    
  } 
  &.kriuk-2 {
    left: -150px;
    // margin-left: -200px;
    top: 50%;
    width: 400px;
    @media (max-width: 992px) {
      width: 380px;
    }
  }
  &.daun-testimonial {
    left: 50%;
    bottom: 590px;
    // background-color:red;
    margin-left: -335px;
    height: 500px;
    width: 790px;
    @media (max-width: 992px) {
      width: 400px;
    }  
  }      
}

#content-tasty-overlay:after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  background: url("../img/content-tasty-kriuk.png") no-repeat top center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#content-tasty-mie{
  position: absolute;
  width: 100%;
  height: 100%;
}

#content-tasty-mie:after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 100px;
  left: 0;
  z-index: 0;
  background: url("../img/content-tasty-mie.png") no-repeat top center;
  // background: url("../img/content-tasty-widescreen.png") no-repeat top center;  
  // background-size: cover;

  @media (min-width: 576px) {

  }

  @media (min-width: 768px) {

  }

  @media (min-width: 992px) {
    // background: red;
  }  

}

#content-tasty-mobile {
  background: url("../img/content-tasty-mobile.png") no-repeat top center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  width: 100%;
  height: 100%;
  margin-top: -150px;
  position: absolute;
  left: 0;
}

.testy-info {
  font-family: "Futura Light", Arial, sans-serif;
  font-size: 13px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  line-height: 200%;
  color: #FFFFFF;
}

.star {
  background: url("../img/star.svg") no-repeat top center;
  background-size: contain;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 15px;
}

.testy-name {
  font-family: "Montserrat", Arial, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  letter-spacing: 0.2em;
  text-transform: capitalize;
  line-height: 200%;
  color: #FFFFFF;
  @media (min-width: 992px) {
    font-size: 15px;
  }
}


// =gallery
#content-ig {
  @include clearfix;
  overflow: hidden;
  .image-gallery {
    float: left;
    width: 100%;
    display: block;
    float: left;
    @include clearfix;
    position: relative;
  }
  .gallery-image {
    background-size: cover;
    background-position: center center;
    float: left;
    &.square {
      width: 20%;
      @include aspect-ratio(1, 1);
      @media (max-width: 992px) {
        width: 33.3333%;
      }
    }
    &.rectangle {
      width: 40%;
      @include aspect-ratio(1, 1);
      @media (max-width: 992px) {
        width: 33.3333%;
      }

    }
    &.last {
      position: absolute;
      left: 0;
      bottom: 0;
      @media (max-width: 992px) {
        right: 0;
        left: auto;
      }

    }    
  }
  .caption {
    background-color: #181818;
    position: absolute;
    left: 0;
    top: 0;
    bottom:0;
    right: 0;
    padding: 20px;
    text-transform: none;
    font-size: 14px;
    line-height: 1.35em;
    opacity: 0;
  }
  .image-1 {
    background-image: url('/img/gallery/big.jpg');
  }
  .image-2 {
    background-image: url('/img/gallery/1.jpg');  
  }
  .image-3 {
    background-image: url('/img/gallery/3.jpg');  
  }
  .image-4 {
    background-image: url('/img/gallery/4.jpg');  
  }
  .image-5 {
    background-image: url('/img/gallery/5.jpg');  
  }  
  .image-6 {
    background-image: url('/img/gallery/6.jpg');  
  }  
  .image-7 {
    background-image: url('/img/gallery/7.jpg');  
  }        
}

.gallery-link {
  width: 100%;
  float: left;
  clear: both;
  margin-top: 20px;
  a {
    font-size: 13px;
    padding: 10px 18px;
    color: #F4E39C;
  }
}

#content-footer {
  font-family: "Futura Light", Arial, sans-serif;
  color: #FFFFFF;
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  background: url("../img/footer-bg-mobile.png") no-repeat top center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  // overflow: hidden;
  .image {
    margin-top: 40px;
  }
  .footer-foreground {
    background: url("../img/footer-foreground.png") no-repeat top center;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: calc(100vh);
    display: block;
  }
  .footer-foreground2 {
    background: url("../img/footer-foreground2-2.png") no-repeat top center;
    background-size: cover;
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: calc(100vh);
    display: block;
  }
  .share a {
    margin: 0 5px;
    display: inline-block;
  }
}

.parallax-footer.daun-2 {
  background: url("../img/footer/daun-2.png") no-repeat top center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  width: 641px;
  height: 633px;
  // background-color: red;
  position: absolute;
  top: -10%;
  left: 60px;
  z-index: 9;
  @media (max-width: 992px) {
    background-size: contain;
    width: 230px;
    height: 190px;
    left: -5%;
    top: 5%;        
  }     
}

.parallax-footer.daun {
  background: url("../img/footer/daun.png") no-repeat top center;
  background-size: contain;
  width: 678px;
  height: 687px;
  position: absolute;
  left: -200px;
  top: -20px;
  // background-color: green;
  z-index: 9;
  overflow: hidden;
  @media (max-width: 992px) {
    width: 400px;
    height: 421px;    
  }  
}

// kriuk
.parallax-footer.kriuk {
  background: url("../img/footer/kriuk.png") no-repeat center 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 475px;
  height: 442px;
  // background-color: blue;
  position: absolute;
  top: -80px;
  right: -140px;
  z-index: 9;
  @media (max-width: 992px) {
    width: 257px;
    height: 321px;    
  }

}

.static-footer {
  position: absolute;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  &.cabe {
    width: 290px;
    height: 220px;
    background-image: url("../img/static-cabe.png");
    left: 10%;
    bottom: 20%;    
    @media (max-width: 992px) {
      left: -120px;
      // height: 421px;    
    }      
  }  
  &.daun {
    width: 330px;
    height: 477px;
    background-image: url("../img/static-daun.png");
    right: 10%;
    bottom: 15%;    
  }
  &.onion {
    width: 230px;
    height: 320px;
    background-image: url("../img/static-onion.png");
    right: 15%;
    top: 5%;
    @media (max-width: 992px) {
      background-size: contain;
      height: 190px;
      right: -120px;
      top: -5%;
      // height: 421px;    
    }        
  }  
}


.margin-footer-1 {
  margin-top: 50px;
}

.icon-instagram {
  background: url("../img/socmed_instagram.svg") no-repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  width: 100%;
  height: 28px;
  display: block;
}

.icon-facebook {
  background: url("../img/socmed_facebook.svg") no-repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  width: 100%;
  height: 28px;
  display: block;
}

.icon-video {
  background: url("../img/socmed_youtube.svg") no-repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  width: 100%;
  height: 28px;
  display: block;
}

.squareme {
  margin-top: 100%;
}

.squareme-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  img {
    text-align: center;
    width: 100%;
    min-height: 100%;
  }
}

.video-hero {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 99;
  justify-content: space-around;
  align-items: center;
  &:after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
  }
}

.video-close {
  background: url("../img/close.png") no-repeat center center;
  background-size: contain;
  width: 30px;
  height: 30px;
  display: block;
  text-indent: -9999px;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 9999999;
}

.playnow {
  cursor: pointer;
}

#comment-gallery {
  z-index: 9;
}

@media (min-width: 480px) {
  #content-tasty-mobile {
    margin-top: -300px;
  }
}

@media (min-width: 576px) {}

@media (min-width: 768px) {}

@media (min-width: 992px) {
  #header {
    background: url("../img/header.png") no-repeat top center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  #content-tasty {
    background: url("../img/content-tasty-table.png") repeat-x top center;
    width: 100%;
    min-height: 100vh;
    display: block;
  }
  #overlay-delicacy1 {
    left: 10%;
    top: 3%;
    z-index: 9;
  }
  #overlay-delicacy2 {
    top: 2%;
    z-index: 9;
  }
  #overlay-delicacy3 {
    right: 10%;
    top: 3%;
    z-index: 9;
  }
  #content-tasty-mobile {
    background: none;
  }
  .new-product {
    background: url("../img/new.png") no-repeat center center;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    width: 129px;
    height: 75px;
    display: block;
  }
  .title-tasty {
    padding-top: 100px;
    font-size: 97px;
  }
  .subtitle {
    font-size: 19px;
  }
  .title-hastag {
    font-size: 35px;
  }
  .tasty-delicacy {
    padding-top: 80px;
    font-size: 64px;
  }
  #overlay-delicacy {
    margin-top: -280px;
  }
  .subtasty-delicacy {
    font-size: 16px;
  }
  .margin-tasty-1 {
    margin-top: 220px;
  }
  .margin-tasty-2 {
    margin-top: 230px;
  }
  .margin-tasty-3 {
    margin-top: 0;
  }
  .margin-tasty-4 {
    margin-top: 100px;
    @media (min-width: 1200px) {
    margin-top: 80px;
    }

  }
  .arrow-left {
    background: url("../img/arrow-left.png") no-repeat top center;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    width: 130px;
    height: 100%;
    position: absolute;
    left: -120px;
    top: 10px;
    z-index: 9;
  }
  .arrow-top {
    background: url("../img/arrow-top.png") no-repeat top center;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    width: 130px;
    height: 130px;
    position: absolute;
    right: -70px;
    top: -100px;
    z-index: 9;
  }
  .delicacy-title {
    font-size: 24px;
  }
  .delicacy-info {
    font-size: 14px;
  }
  .margin-tasty-1 .delicacy-title:before, .margin-tasty-2 .delicacy-title:before, .margin-tasty-3 .delicacy-title:before {
    display: none;
  }
  #content-footer {
    background: url("../img/footer-bg.png") no-repeat top center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  #content-footer .image {
    margin-top: 160px;
  }
  .margin-footer-1 {
    margin-top: 150px;
  }
}

@media (min-width: 1200px) {
  .margin-tasty-1, .margin-tasty-2 {
    margin-top: 250px;
  }
  .margin-tasty-3 {
    margin-top: 0;
  }
}